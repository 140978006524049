import React, { Component } from "react";
import {
  getCard,
  getUploadUrlForTranscode,
  getTranscodedUpload,
  validateStreamUrl,
  updateCard,
  addIconsToUser,
} from "../utils/yoto-api";
import { browserHistory } from "react-router";
import ErrorBoundary from "./ErrorBoundary";
import axios from "axios";
import PageHeader from "./PageHeaderMyoMenu";
import swal from "@sweetalert/with-react/dist/sweetalert";
import queryString from "qs";

import shortId from "short-uuid";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import AutoLogout from "./AutoLogout";
import CoverImageDetail from "./card-edit/coverImageDetail";

import IconSelector from "./dialogs/IconSelector";
import SortableChapterTrack from "./card-edit/sortableChapterTrack";

import { sortableContainer } from "react-sortable-hoc";
import { FormGroup, TextField } from "@material-ui/core";
import { PrimaryButton, SecondaryButton } from "./Button";
import { isLoggedIn, login } from "../utils/AuthService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import DeleteCardButton from "./DeleteCardButton";
import * as hashCalculator from "../utils/hashCalculator";

const MENU_TAB_PLAYLISTS = "playlists";

const SortableContainer = sortableContainer(({ children }) => {
  return <div style={{}}>{children}</div>;
});

//const defaultIcon = "https://static.yotoplay.com/img/ugc-default-icon.png#sha256=wNzIBm6LnVS81H0bS8yjsi6ZUTXeQBqiG73bfHeB6Uk&directDownload=true";
const DEFAULT_ICON_MEDIAID = "aUm9i3ex3qqAMYBv-i-O-pYMKuMJGICtR3Vhf289u2Q";

const propTypes = {
  POLLING_INTERVAL: PropTypes.number,
  location: PropTypes.object,
  params: PropTypes.object,
  i18n: PropTypes.object,
};

const DEFAULT_CONTENT_ACTIVITY_TYPE = "yoto_Player";

const URL_MY_CARDS = "/my-cards";

const YOTO_MEDIA_URL_PREFIX = "yoto:#";

const STATUS_UPLOAD = "upload";
const STATUS_FAILED = "failed";
const STATUS_TRANSCODING = "transcode";

const TRACK_TYPE_AUDIO = "audio";
const TRACK_TYPE_STREAM = "stream";

const TRACK_LIMIT = 100;
const POLLING_INTERVAL = 3000;

const AUTO_OVERLAY_LABELS_DISABLED = "disabled";

const arrayMove = (array, from, to) => {
  array.splice(to, 0, array.splice(from, 1)[0]);
};

const formatDuration = (durationInSeconds) => {
  if (!durationInSeconds) {
    return "";
  }
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor((durationInSeconds % 3600) % 60);

  return `${hours}h ${minutes}m ${seconds}s`;
};

class EditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: null,
      saved: false,
      readyToSave: false,
      working: false,
      requestCardId: props.params.cardId,
      streamUrl: "",
      streamUrlError: null,
      os: null,
      share: false,
      coverImageUploading: false,
      iconDialogOpenChapterKey: null,
    };
    this.i18n = this.props.i18n;
  }

  async componentDidMount() {
    if (!isLoggedIn()) {
      login(this.props.location.pathname);
    }
    const { cardId } = this.props.params;
    if (cardId) {
      getCard(cardId).then((card) => {
        //console.log(JSON.stringify(card));
        if (!card.metadata) card.metadata = {};
        if (card.availability) {
          delete card.availability;
        }
        this.refreshCard(card);
      });
    } else {
      this.setState({ card: this.createNewCard() });
    }
    //this.osType = this.props.params.deviceId;
    const qs = queryString.parse(this.props.location.search.split("?")[1]);
    //console.log(qs);
    if (qs.os) this.setState({ os: qs.os.toLowerCase() });
    setInterval(
      () => this.checkTranscodes(),
      this.props.POLLING_INTERVAL || POLLING_INTERVAL,
    );
  }

  async checkTranscodes() {
    let card = this.state.card;
    if (!card || !card.content || !card.content.chapters) return;
    for (let c of card.content.chapters) {
      if (c.tracks && c.tracks.length > 0) {
        for (let t of c.tracks) {
          if (
            t._control &&
            t._control.uploadId &&
            t._control.statusCode === STATUS_TRANSCODING
          ) {
            if (t._control.transcodeAttempt == null)
              t._control.transcodeAttempt = 0;
            const transcode = await getTranscodedUpload(t._control.uploadId);
            const mediaInfo = transcode.transcodedInfo;
            t._control.transcodeAttempt++;
            if (transcode && transcode.startedAt)
              t._control.transcodeStartedAt = transcode.startedAt;

            const successfullTranscode =
              transcode && transcode.transcodedSha256;

            let timeoutElapsed = t._control.transcodeAttempt > 300;
            let startedAtValueRevoked =
              t._control.transcodeStartedAt && !transcode.startedAt;
            const failedTranscode =
              timeoutElapsed || startedAtValueRevoked || transcode.erroredAt;

            if (successfullTranscode) {
              this.updateChapterWithTrack(c.key, {
                _control: {
                  statusMessage: null,
                  statusCode: null,
                },
                title:
                  mediaInfo &&
                  mediaInfo.metadata &&
                  mediaInfo.metadata.title &&
                  !mediaInfo.metadata.title
                    .toLowerCase()
                    .startsWith("new recording")
                    ? mediaInfo.metadata.title
                    : undefined,
                duration: mediaInfo.duration,
                fileSize: mediaInfo.fileSize,
                channels: mediaInfo.channels,
                format: mediaInfo.format,
                trackUrl: `yoto:#${transcode.transcodedSha256}`,
              });
              console.log(`Uploaded ${t.title}`);
            } else if (failedTranscode) {
              this.updateChapterWithTrack(c.key, {
                _control: {
                  statusMessage: this.i18n.t(
                    "myo_track_status_error_failed_to_process_upload",
                    "Failed to process file. Make sure it's a valid MP3, M4A or AAC audio.",
                  ),
                  statusCode: STATUS_FAILED,
                },
              });
            } else {
              t._control.statusMessage = `${this.i18n.t(
                "myo_track_status_processing",
                "Processing...",
              )} (${t._control.transcodeAttempt})`;
              this.updateChapterWithTrack(c.key, t._control);
            }
          }
        }
      }
    }
  }

  handleIconDialogOpen(chapterKey) {
    this.setState({ iconDialogOpenChapterKey: chapterKey });
  }

  handleIconDialogCancel() {
    this.setState({ iconDialogOpenChapterKey: null });
  }

  changeChapterIcon(chapterKey, url) {
    //prevent user quickly clicking an icon twice before the browser has time to hide the dialog
    if (!this.state.iconDialogOpenChapterKey) {
      return;
    }
    this.setState({ iconDialogOpenChapterKey: null });
    console.log("close icon dialog");
    if (chapterKey === null) {
      const { card } = this.state;
      const { chapters } = card.content;
      if (!chapters) return;
      for (const c of chapters) {
        const track = c.tracks ? c.tracks[0] : null;
        if (!c.display) c.display = {};
        c.display.icon16x16 = url;
        if (!track) continue;
        if (!track.display) track.display = {};
        track.display.icon16x16 = url;
      }
      this.refreshCard(card);
    } else {
      this.updateChapterWithTrack(chapterKey, {
        displayIcon16x16: url,
      });
    }
  }

  addChapterWithTrack(trackProps) {
    const { card } = this.state;
    if (!card.content.chapters) card.content.chapters = [];
    const no = card.content.chapters.length + 1;
    const newChapter = {
      key: shortId.generate(),
      title: trackProps.title,
      overlayLabel: no.toString(),
      tracks: [
        {
          _control: {},
          key: "01",
          title: trackProps.title,
          format: trackProps.format,
          trackUrl: trackProps.trackUrl,
          type: trackProps.type,
          overlayLabel: no.toString(),
          display: {
            icon16x16: `yoto:#${DEFAULT_ICON_MEDIAID}`,
          },
        },
      ],
      display: {
        icon16x16: `yoto:#${DEFAULT_ICON_MEDIAID}`,
      },
    };
    card.content.chapters.push(newChapter);
    this.refreshCard(card);
    return newChapter.key;
  }

  updateChapterWithTrack(chapterKey, updatedProps) {
    //only statusCode, statusMessage, title, trackUrl, format, type, displayIcon16x16 can be updated
    const { card } = this.state;
    let chapter = card.content.chapters.find((c) => c.key === chapterKey);
    const track = chapter.tracks[0];
    if (!chapter) return null;
    if (updatedProps.title) {
      chapter.title = updatedProps.title;
      track.title = updatedProps.title;
    }
    if (updatedProps.displayIcon16x16 != null) {
      if (!chapter.display) chapter.display = {};
      chapter.display.icon16x16 = updatedProps.displayIcon16x16;
      if (!track.display) track.display = {};
      track.display.icon16x16 = updatedProps.displayIcon16x16;
    }
    if (updatedProps._control) {
      if (updatedProps._control.statusCode !== undefined)
        track._control.statusCode = updatedProps._control.statusCode;
      if (updatedProps._control.statusMessage !== undefined)
        track._control.statusMessage = updatedProps._control.statusMessage;
      if (updatedProps._control.percentage !== undefined)
        track._control.percentage = updatedProps._control.percentage;
      if (updatedProps._control.uploadId !== undefined)
        track._control.uploadId = updatedProps._control.uploadId;
      if (updatedProps._control.transcodeAttempt !== undefined)
        track._control.transcodeAttempt =
          updatedProps._control.transcodeAttempt;
    }
    if (updatedProps.trackUrl !== undefined)
      track.trackUrl = updatedProps.trackUrl;
    if (updatedProps.format !== undefined) track.format = updatedProps.format;
    if (updatedProps.type !== undefined) track.type = updatedProps.type;
    if (updatedProps.duration !== undefined) {
      track.duration = updatedProps.duration;
    }
    if (updatedProps.fileSize !== undefined) {
      track.fileSize = updatedProps.fileSize;
    }
    if (updatedProps.channels !== undefined)
      track.channels = updatedProps.channels;

    if (updatedProps.onEndEvent !== undefined) {
      if (!track.events) track.events = {};
      track.events.onEnd = { cmd: updatedProps.onEndEvent };
    }
    this.refreshCard(card);
  }

  deleteChapter(chapterKey) {
    const { card } = this.state;
    card.content.chapters = card.content.chapters.filter(
      (c) => c.key !== chapterKey,
    );
    this.refreshCard(card);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { card } = this.state;
    arrayMove(card.content.chapters, oldIndex, newIndex);
    this.refreshCard(card);
  }

  refreshCard(card) {
    let uploadedTracks = 0;
    let inProgressTracks = 0;
    let cardDuration = 0;
    let cardFileSize = 0;
    let cardHasStreams = false;
    let autoOverlayLabels = !(
      card.content &&
      card.content.editSettings &&
      card.content.editSettings.autoOverlayLabels ===
        AUTO_OVERLAY_LABELS_DISABLED
    );
    for (let [cNo, c] of card.content.chapters.entries()) {
      c.overlayLabel = autoOverlayLabels ? (cNo + 1).toString() : "";
      let chapterDuration = 0;
      let chapterFileSize = 0;
      let chapterHasStreams = false;
      if (c.tracks && c.tracks.length > 0) {
        c.tracks[0].overlayLabel = c.overlayLabel;
        for (let t of c.tracks) {
          if (t.type === TRACK_TYPE_STREAM) chapterHasStreams = true;
          if (!t.trackUrl && t._control && !t._control.statusCode) {
            t._control.statusMessage = this.i18n.t(
              "myo_track_status_error_failed_adding_track",
              "Failed adding track.",
            );
            t._control.statusCode = STATUS_FAILED;
          }
          if (!t._control || !t._control.statusCode) {
            uploadedTracks++;
            if (t.duration) chapterDuration += t.duration;
            if (t.fileSize) chapterFileSize += t.fileSize;
          } else {
            inProgressTracks++;
          }
        }
      }
      cardDuration += chapterDuration;
      cardFileSize += chapterFileSize;
      cardHasStreams = cardHasStreams || chapterHasStreams;
    }
    if (!card.content.config) card.content.config = {};
    if (!card.metadata) card.metadata = {};
    if (!card.metadata.media) card.metadata.media = {};
    card.metadata.media.duration = cardDuration;
    card.metadata.media.readableDuration = formatDuration(cardDuration);
    card.metadata.media.fileSize = cardFileSize;
    card.metadata.media.readableFileSize =
      Math.round((cardFileSize / 1024 / 1024) * 10) / 10;
    card.metadata.media.hasStreams = cardHasStreams;

    if (card.content.config.shuffle && card.content.config.shuffle.length > 0) {
      card.content.config.shuffle = [
        {
          start: 0,
          end: card.content.chapters.length - 1,
          limit: card.content.chapters.length,
        },
      ];
    }

    this.setState({ card });
    this.setState({
      readyToSave: uploadedTracks > 0 && inProgressTracks === 0,
    });
  }

  updateChapterTitle(chapterKey, title) {
    const { card } = this.state;
    let chapter = card.content.chapters.find((c) => c.key === chapterKey);
    const track = chapter.tracks[0];
    if (!chapter) return null;
    chapter.title = title;
    track.title = title;
    this.setState({ card });
  }

  createNewCard() {
    return {
      content: {
        activity: DEFAULT_CONTENT_ACTIVITY_TYPE,
        chapters: [],
        restricted: true,
        config: { onlineOnly: false },
        version: "1",
      },
      metadata: {},
    };
  }

  updateTitle(title) {
    const { card } = this.state;
    card.title = title;
    this.refreshCard(card);
  }

  updateDescription(description) {
    const { card } = this.state;
    card.metadata.description = description;
    this.refreshCard(card);
  }

  toggleShuffle() {
    const { card } = this.state;
    if (card.content.config && card.content.config.shuffle) {
      delete card.content.config.shuffle;
    } else {
      card.content.config.shuffle = [{ ignored: null }];
    }
    this.refreshCard(card);
  }

  toggleHideTrackNumbers() {
    const { card } = this.state;
    if (
      card.content.editSettings &&
      card.content.editSettings.autoOverlayLabels
    ) {
      delete card.content.editSettings.autoOverlayLabels;
    } else {
      if (!card.content.editSettings) card.content.editSettings = {};
      card.content.editSettings.autoOverlayLabels =
        AUTO_OVERLAY_LABELS_DISABLED;
    }
    this.refreshCard(card);
  }

  toggleDontResume() {
    const { card } = this.state;
    if (card.content.config && card.content.config.resumeTimeout === 0) {
      delete card.content.config.resumeTimeout;
    } else {
      if (!card.content.config) card.content.config = {};
      card.content.config.resumeTimeout = 0;
    }
    this.refreshCard(card);
  }

  checkTitleAndSave() {
    const { card } = this.state;
    if (card.title) return this.save();
    swal({
      buttons: {
        cancel: this.i18n.t("common_cancel_button", "Cancel"),
        confirm: {
          className: "swal-ok-button",
          text: this.i18n.t("common_save_button", "Save"),
        },
      },
      content: (
        <div>
          <h3>
            <Trans i18nKey="myo_playlist_title_dialog_title">
              Give me a name:
            </Trans>
          </h3>
          <input
            type="text"
            style={{ width: "90%", border: "1px #" }}
            placeholder={this.i18n.t(
              "myo_playlist_title_example",
              "e.g. Grandmas Bedtime Stories",
            )}
            onChange={(event) => this.updateTitle(event.target.value)}
          />
        </div>
      ),
    }).then((value) => {
      if (!value) return;
      this.save();
    });
  }

  removeControlProperties(card) {
    if (!card || !card.content || !card.content.chapters) return card;
    for (let [cNo, c] of card.content.chapters.entries()) {
      c.key = `00${cNo}`.slice(-2);
      if (c.tracks && c.tracks.length > 0) {
        for (let t of c.tracks) {
          delete t._control;
        }
      }
    }
    return card;
  }

  async save() {
    if (this.state.working) return;
    this.setState({ working: true });
    const { card } = this.state;

    if (!this.withinCardLimits(card)) {
      this.setState({ working: false });
      return;
    }

    try {
      await this.uploadCardImages(card);
      const publishedCard = await updateCard(
        this.removeControlProperties(card),
      );
      card.cardId = publishedCard.cardId;
      this.refreshCard(card);
      browserHistory.push(URL_MY_CARDS);
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ working: false });
    }
  }

  withinCardLimits(card) {
    return card.content.chapters.length <= TRACK_LIMIT;
  }

  async uploadCardImages(card) {
    const images = new Set();
    for (const chapter of card.content.chapters) {
      if (chapter.display && chapter.display.icon16x16)
        images.add(chapter.display.icon16x16);
      for (const track of chapter.tracks) {
        if (track.display && track.display.icon16x16)
          images.add(track.display.icon16x16);
      }
    }
    //for (const imgUrl of images) await this.uploadImage(imgUrl);
    await addIconsToUser(
      Array.from(images)
        .filter((i) => i.startsWith(YOTO_MEDIA_URL_PREFIX))
        .map((i) => ({ mediaId: i.split(YOTO_MEDIA_URL_PREFIX)[1] })),
    );
  }

  async uploadCardTrackFile(file) {
    const i18n = this.i18n;
    const chapterKey = this.addChapterWithTrack({
      title: file.name.replace(/\.[^/.]+$/, ""),
      type: TRACK_TYPE_AUDIO,
    });
    this.updateChapterWithTrack(chapterKey, {
      _control: {
        statusMessage: i18n.t(
          "myo_track_status_preparing_upload",
          "Preparing upload...",
        ),
        statusCode: STATUS_UPLOAD,
      },
    });
    if (
      /\.(mp3|m4a|m4b|aac|ogg|flac)$/i.test(file.name) === false &&
      !file.type.startsWith("audio/")
    ) {
      let statusMessage = i18n.t(
        "myo_track_status_error_invalid_audio_format",
        "Invalid audio file format. Supported formats are MP3, M4A/M4B, AAC and FLAC.",
      );
      if (
        file.name.indexOf(".") < 0 ||
        file.name.length - file.name.lastIndexOf(".") >= 8
      ) {
        statusMessage = i18n.t(
          "myo_track_status_error_missing_extension",
          "File is missing filename extension (e.g. .mp3 or .m4a)",
        );
      }
      this.updateChapterWithTrack(chapterKey, {
        _control: {
          statusMessage,
          statusCode: STATUS_FAILED,
        },
      });
      this.refreshCard(this.state.card);
      return;
    }

    try {
      const hash = await hashCalculator.calculateFileHash(file);

      // added for tracking issue where files from cloud locations (notably Google Drive) are read an an empty string despite having file size > 0
      if (hash === "47DEQpj8HBSa-_TImW-5JCeuQeRkm5NMpJWZG3hSuFU") {
        console.error(`The file could not be read or is empty - ${file}`);
      }

      const upload = await getUploadUrlForTranscode(hash, file.name);
      if (upload.uploadUrl) {
        this.updateChapterWithTrack(chapterKey, {
          _control: {
            statusMessage: i18n.t("myo_track_status_uploading", "Uploading"),
            statusCode: STATUS_UPLOAD,
          },
        });
        const fileUploadProgress = (chapterKey) => (progress) => {
          let percentage = Math.floor((progress.loaded * 100) / progress.total);
          this.updateChapterWithTrack(chapterKey, {
            _control: {
              statusMessage: `${i18n.t(
                "myo_track_status_uploading",
                "Uploading",
              )} (${percentage}%)`,
              percentage,
            },
          });
        };

        await axios.put(
          upload.uploadUrl,
          new Blob([file], { type: file.type, ContentDisposition: file.name }),
          {
            onUploadProgress: fileUploadProgress(chapterKey),
          },
        );
      }

      this.updateChapterWithTrack(chapterKey, {
        _control: {
          uploadId: upload.uploadId,
          statusMessage: i18n.t("myo_track_status_processing", "Processing..."),
          statusCode: STATUS_TRANSCODING,
        },
      });
    } catch (err) {
      console.error(err);
      this.updateChapterWithTrack(chapterKey, {
        _control: {
          statusMessage: i18n.t(
            "myo_track_status_error_upload_failed",
            "Upload failed",
          ),
          statusCode: STATUS_FAILED,
        },
      });
    }
  }

  async handleFileSelect(evt) {
    const files = evt.target.files; // FileList object

    for (let i = 0; i < files.length; i++) {
      await this.uploadCardTrackFile(files[i]);
    }
  }

  cleanStreamUrl(url) {
    const streamUrl = new URL(url);
    streamUrl.protocol = streamUrl.protocol.toLowerCase();
    return streamUrl.toString();
  }

  updateCoverImage(cardMetadata, imageUrl) {
    if (!cardMetadata.cover) cardMetadata.cover = {};
    cardMetadata.cover.imageL = imageUrl;
  }

  async addStreamUrl() {
    const i18n = this.i18n;
    this.setState({ streamUrl: null });
    const value = await swal({
      buttons: {
        cancel: i18n.t("common_cancel_button", "Cancel"),
        confirm: {
          className: "swal-ok-button",
          text: i18n.t("common_save_button", "Save"),
          closeModal: false,
        },
      },
      content: (
        <div>
          <h3>
            <Trans i18nKey="myo_add_stream_dialog_title">Add Stream</Trans>
          </h3>
          <p>
            <Trans i18nKey="myo_add_stream_instructions">
              Please enter a direct link to the audio stream (rather than just a
              link to a web page with an audio player)
              <br />
              <br />
              e.g. https://s3.radio.co/sd4bb72a6a/listen.
            </Trans>
          </p>
          <input
            type="text"
            style={{
              width: "95%",
              height: "50px",
              marginTop: "10px",
              background: "#FFF",
              border: "1px #a6a6a6 solid",
              borderRadius: "8px",
              paddingLeft: "10px",
            }}
            placeholder={i18n.t(
              "myo_add_stream_url_placeholder",
              "Paste your link here",
            )}
            onChange={(event) =>
              this.setState({ streamUrl: event.target.value })
            }
          />
          <div>{this.state.streamUrlError}</div>
        </div>
      ),
    });

    if (!value || !this.state.streamUrl) {
      swal.close();
      return;
    }
    try {
      const stream = await validateStreamUrl(this.state.streamUrl);
      stream.url = this.cleanStreamUrl(stream.url);
      this.addChapterWithTrack({
        title: stream.url,
        type: TRACK_TYPE_STREAM,
        trackUrl: stream.url,
        format: stream.format,
      });
    } catch (err) {
      swal(
        null,
        i18n.t(
          "myo_error_unsupported_stream",
          "Unfortunately we couldn't find a supported audio stream at this URL.",
          "error",
        ),
      );
    } finally {
      swal.stopLoading();
      swal.close();
    }
  }

  render() {
    const { card, readyToSave, working, requestCardId, os } = this.state;
    const shuffle =
      card &&
      card.content.config &&
      card.content.config.shuffle &&
      card.content.config.shuffle.length > 0;
    const autoOverlayLabels = !(
      card &&
      card.content.editSettings &&
      card.content.editSettings.autoOverlayLabels ===
        AUTO_OVERLAY_LABELS_DISABLED
    );
    const dontResume =
      card && card.content.config && card.content.config.resumeTimeout === 0;
    const readOnly = card && card.readOnly === true;

    //    const { t } = this.props;
    const i18n = this.props.i18n;

    return (
      <ErrorBoundary>
        <AutoLogout />
        <PageHeader
          selected={MENU_TAB_PLAYLISTS}
          title={
            card
              ? card.title ||
                i18n.t("myo_edit_playlist_title_create", "Add a playlist")
              : i18n.t("common_loading", "Loading...")
          }
          i18n={i18n}
        />
        {card ? (
          <span>
            <div className={"btn-foot-bar"}>
              <div className="container content" style={{ padding: 0 }}>
                <div className="row">
                  {readOnly ? (
                    <div
                      className="col-lg-12 col-12"
                      style={{ textAlign: "center", padding: "12px" }}
                    >
                      <SecondaryButton
                        variant="contained"
                        style={{ width: "100%", maxWidth: "300px" }}
                        onClick={browserHistory.goBack}
                      >
                        <Trans i18nKey="common_close_button">Close</Trans>
                      </SecondaryButton>
                    </div>
                  ) : (
                    <>
                      <div
                        className="col-lg-6 col-6"
                        style={{ textAlign: "right", padding: "12px" }}
                      >
                        <SecondaryButton
                          variant="contained"
                          style={{ width: "100%", maxWidth: "300px" }}
                          onClick={browserHistory.goBack}
                        >
                          <Trans i18nKey="common_cancel_button">Cancel</Trans>
                        </SecondaryButton>
                      </div>
                      <div
                        className="col-lg-6 col-6"
                        style={{ textAlign: "left", padding: "12px" }}
                      >
                        <PrimaryButton
                          style={{ width: "100%", maxWidth: "300px" }}
                          className="btn primary-button create-btn"
                          disabled={
                            !readyToSave ||
                            working ||
                            !this.withinCardLimits(card) ||
                            readOnly
                          }
                          onClick={() => this.checkTitleAndSave()}
                        >
                          {requestCardId
                            ? i18n.t("myo_update_playlist_button", "Update")
                            : i18n.t("myo_save_playlist_button", "Create")}
                        </PrimaryButton>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="container content">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <h1 className="myo-title">
                    {!requestCardId
                      ? i18n.t(
                          "myo_edit_playlist_title_create",
                          "Add a playlist",
                        )
                      : !readOnly
                      ? i18n.t(
                          "myo_edit_playlist_title_edit_existing",
                          "Edit your playlist",
                        )
                      : i18n.t(
                          "myo_edit_playlist_title_readonly",
                          "Shared playlist",
                        )}
                  </h1>
                  {!requestCardId ? (
                    <p className="myo-subheading">
                      {i18n.t(
                        "myo_edit_playlist_subheading_create",
                        "Create a new playlist by uploading audio from your device or linking a stream.",
                      )}
                    </p>
                  ) : readOnly ? (
                    <p className="myo-subheading">
                      {i18n.t(
                        "myo_edit_playlist_subheading_readonly",
                        "This playlist has public sharing enabled and cannot be edited anymore.",
                      )}
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-sm-12 playlist-name-block">
                  <h5 className="field-label">
                    <Trans i18nKey="myo_edit_playlist_title">
                      Give me a name
                    </Trans>
                  </h5>
                  <TextField
                    fullWidth
                    variant={"outlined"}
                    placeholder={i18n.t(
                      "myo_default_playlist_title_placeholder",
                      "Playlist name",
                    )}
                    value={card.title}
                    disabled={readOnly}
                    onChange={(event) => {
                      this.updateTitle(event.target.value.substring(0, 50));
                    }}
                  />
                </div>
                <div className="col-lg-12 col-sm-12 playlist-name-block">
                  <h5 className="field-label">
                    <Trans i18nKey="myo_edit_playlist_description">
                      Add a description
                    </Trans>
                  </h5>
                  <TextField
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    disabled={readOnly}
                    variant={"outlined"}
                    placeholder={i18n.t(
                      "myo_edit_playlist_description_placeholder",
                      "Optional, maximum 500 characters.",
                    )}
                    value={card.metadata.description}
                    onChange={(event) => {
                      this.updateDescription(
                        event.target.value.substring(0, 500),
                      );
                    }}
                  />
                </div>
                <div className="col-lg-3 col-sm-12 artwork-upload-container">
                  <h5 className="field-label">
                    <Trans i18nKey="myo_edit_choose_artwork">
                      Choose some artwork
                    </Trans>
                  </h5>
                  <CoverImageDetail
                    image={
                      card.metadata.cover ? card.metadata.cover.imageL : null
                    }
                    onChange={(imageUrl) => {
                      this.updateCoverImage(card.metadata, imageUrl);
                    }}
                  />
                </div>

                <div
                  className="col-lg-9 col-sm-12"
                  style={{ textAlign: "left" }}
                >
                  <div className="content">
                    <div
                      className="row"
                      style={{ marginBottom: "30px", marginTop: "10px" }}
                    >
                      <div
                        className="col-lg-6 col-12"
                        style={{ paddingBottom: "12px" }}
                      >
                        <PrimaryButton
                          variant="contained"
                          disabled={!!working || readOnly}
                          component={!readOnly ? "label" : ""}
                        >
                          <input
                            disabled={!!working || readOnly}
                            id="upload"
                            type="file"
                            style={{ display: "none" }}
                            accept=".m4a,.m4b,.mp3,.aac"
                            onChange={(event) => {
                              this.handleFileSelect(event);
                            }}
                            multiple
                          />
                          <Trans i18nKey="myo_add_audio_button">
                            Add audio
                          </Trans>
                        </PrimaryButton>
                      </div>
                      <div
                        className="col-lg-6 col-12"
                        style={{ paddingBottom: "12px" }}
                      >
                        {/* <button style={{ width: '100%' }}  type="button"  className="btn btn-info blue-button"
                                                onClick={() => this.addStreamUrl()}
                                                disabled={!!working}><Trans i18nKey='myo_add_stream_button'>Add Stream</Trans>
                                            </button> */}
                        <SecondaryButton
                          disabled={!!working || readOnly}
                          onClick={() => this.addStreamUrl()}
                        >
                          <Trans i18nKey="myo_add_stream_button">
                            Add stream
                          </Trans>
                        </SecondaryButton>
                      </div>
                    </div>

                    {card.content.chapters.reduce(
                      (totalTracks, ch) => ch.tracks.length + totalTracks,
                      0,
                    ) === 0 ? (
                      <div className="row" style={{ textAlign: "center" }}>
                        <div className="col-lg-12 col-sm-12">
                          <div>
                            <img
                              alt=""
                              style={{
                                maxWidth: "250px",
                                marginBottom: "16px",
                              }}
                              src="/img/add-tracks-illustration.png"
                            />
                            <p className="p-bold empty-playlist">
                              <Trans i18nKey="myo_tracks_empty_state_title">
                                Nothing added yet
                              </Trans>
                            </p>
                            <p className="empty-playlist">
                              <Trans i18nKey="myo_tracks_empty_state">
                                Click one of the buttons above to start adding
                                some audio
                              </Trans>
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="row" style={{ textAlign: "center" }}>
                          <div className="col-lg-12 col-sm-12">
                            <SortableContainer
                              onSortEnd={(event) => this.onSortEnd(event)}
                              useDragHandle
                            >
                              {card.content.chapters.map(
                                (chapter, chapterIndex) =>
                                  chapter.tracks.map((track, trackIndex) => (
                                    <SortableChapterTrack
                                      key={`track${chapter.key}_${trackIndex}`}
                                      i18n={i18n}
                                      chapterIndex={chapterIndex}
                                      chapter={chapter}
                                      track={track}
                                      readOnly={readOnly}
                                      totalChapters={
                                        card.content.chapters.length
                                      }
                                      handleIconDialogOpen={(chapterKey) =>
                                        this.handleIconDialogOpen(chapterKey)
                                      }
                                      updateChapterTitle={(chapterKey, title) =>
                                        this.updateChapterTitle(
                                          chapterKey,
                                          title,
                                        )
                                      }
                                      deleteChapter={(chapterKey) =>
                                        this.deleteChapter(chapterKey)
                                      }
                                      updateChapterOnEndEvent={(
                                        chapterKey,
                                        onEndEvent,
                                      ) =>
                                        this.updateChapterWithTrack(
                                          chapterKey,
                                          { onEndEvent },
                                        )
                                      }
                                    ></SortableChapterTrack>
                                  )),
                              )}
                            </SortableContainer>
                          </div>
                        </div>
                        {!this.withinCardLimits(card) && (
                          <div className="message">
                            {this.i18n.t(
                              "myo_over_limit",
                              "The playlist is over the allowed limit (100 tracks). Please remove some tracks.",
                            )}
                          </div>
                        )}
                        <div
                          className="row"
                          style={{ marginTop: "25px", textAlign: "right" }}
                        >
                          <p>
                            <strong>
                              {this.i18n.t("myo_total", "Total")}:{" "}
                              {`${this.state.card.metadata.media.readableDuration}`}{" "}
                              /{" "}
                              {`${this.state.card.metadata.media.readableFileSize}MB`}
                            </strong>
                          </p>
                        </div>
                        <div className="row">
                          <div className="col-lg-12 col-12">
                            <h5 style={{ marginTop: "32px" }}>
                              <Trans i18nKey="myo_edit_options_title">
                                Options
                              </Trans>
                            </h5>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={readOnly}
                                    checked={dontResume}
                                    onChange={() => {
                                      this.toggleDontResume();
                                    }}
                                  />
                                }
                                label={this.i18n.t(
                                  "myo_edit_options_dont_resume",
                                  "Always play from start",
                                )}
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={readOnly}
                                    checked={shuffle}
                                    onChange={() => {
                                      this.toggleShuffle();
                                    }}
                                  />
                                }
                                label={this.i18n.t(
                                  "myo_edit_options_shuffle",
                                  "Shuffle tracks each time played",
                                )}
                              />
                            </FormGroup>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    disabled={readOnly}
                                    checked={!autoOverlayLabels}
                                    onChange={() => {
                                      this.toggleHideTrackNumbers();
                                    }}
                                  />
                                }
                                label={this.i18n.t(
                                  "myo_edit_options_hide_track_numbers",
                                  "Hide track numbers",
                                )}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </>
                    )}
                    {readOnly ? (
                      ""
                    ) : (
                      <div className="row">
                        <div className="col-lg-12 col-12">
                          <h5 style={{ marginTop: "16px" }}>
                            <Trans i18nKey="myo_edit_tips_title">
                              Some tips
                            </Trans>
                          </h5>
                          {/*TODO: Figure out how to interpolate an <a> tag inside <Trans/> component*/}
                          <ul>
                            <li>
                              <Trans i18nKey="myo_edit_tips_add_multiple">
                                Add multiple files at once
                              </Trans>
                            </li>
                            <li>
                              <Trans i18nKey="myo_edit_tips_reorder">
                                Drag and drop to reorder
                              </Trans>
                            </li>
                            <li>
                              <Trans i18nKey="myo_edit_tips_icons">
                                Tap the pixel art to give each track its own
                                look
                              </Trans>
                            </li>
                            <li>
                              <Trans i18nKey="myo_edit_tips_limits">
                                How much can I put on a card? &#40;
                                <strong>
                                  <a
                                    href={i18n.t(
                                      "myo_edit_tips_limits_link",
                                      "https://support.yotoplay.com/en-US/how-much-content-you-can-put-on-a-make-your-own-card-11564",
                                    )}
                                    style={{ color: "#003c1f" }}
                                    target="_new"
                                  >
                                    find out here
                                  </a>
                                </strong>
                                &#41;
                              </Trans>
                            </li>
                            <li>
                              <Trans i18nKey="myo_edit_tips_player_only_options">
                                Shuffle, track pause and repeat options are only
                                supported on Yoto players (not if played in the
                                app)
                              </Trans>
                            </li>
                            {os === "android" || os === "ios" ? (
                              <li>
                                <Trans i18nKey="myo_mobile_instructions_for_desktop_upload">
                                  If you need to upload files from another
                                  device, you can also edit this playlist at{" "}
                                  <a href="https://yotoplay.com/create">
                                    yotoplay.com/create
                                  </a>
                                </Trans>
                              </li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </div>
                      </div>
                    )}
                    {!requestCardId ? (
                      ""
                    ) : (
                      <>
                        <div className="divider-row"></div>
                        <div className="row">
                          <div className="col-lg-6 col-12">
                            <DeleteCardButton
                              cardId={card.cardId}
                              cardTitle={card.title}
                              cardType={card.metadata.category}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="cardLibSpacer" />
                  </div>
                </div>
              </div>
            </div>
          </span>
        ) : (
          ""
        )}

        <IconSelector
          open={this.state.iconDialogOpenChapterKey !== null}
          chapterKey={this.state.iconDialogOpenChapterKey}
          onClose={() => this.handleIconDialogCancel()}
          onChange={(chapterKey, { url }) => {
            this.changeChapterIcon(chapterKey, url);
          }}
        />
      </ErrorBoundary>
    );
  }
}

EditCard.propTypes = propTypes;

export default withTranslation()(EditCard);
